@tailwind base;
@tailwind components;
@tailwind utilities;

.tooltip:after {
    content: "";
    position: absolute;
    left: 23px;
    bottom: -15px;
    border-top: 8px solid #111827;
    border-right: 7px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 7px solid transparent;
}

.scrollbar::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}
.scrollbar::-webkit-scrollbar-track {
    background: transparent;
}
.scrollbar::-webkit-scrollbar-thumb {
    background: #D9D9D9;
    border-radius: 10px;
}
.scrollbar::-webkit-scrollbar-thumb:hover {
    background: #D9D9D9;
}

input{
    background-color: transparent;
}
input[type=number] {
    -moz-appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

select {
    appearance: none;
    cursor: pointer;
    background-image: url("assets/icons/arrow-down.svg");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 10px;
}

.PhoneInputInput {
    outline: none;
}

/* note tab generate pdf */

.generated-html{
    font-family: 'Mulish', sans-serif;
    font-size: 14px;
}
.generated-html > .last-line {
    height: 6px;
    width: 1px;
}

.generated-html > ul > li {
    margin-left: 14px;
    padding-left: 16px;
    position: relative;
}
.generated-html > ul > li::before {
    content: '';
    height: 6px;
    width: 6px;
    border-radius: 6px;
    background-color: black;
    top: 16px;
    left: 0;
    position: absolute;
}
.generated-html > ul > ul > li{
    margin-left: 30px;
    padding-left: 16px;
    position: relative;
}
.generated-html > ul > ul > li::before {
    content: '';
    height: 6px;
    width: 6px;
    border-radius: 6px;
    border: 1px solid black;
    top: 16px;
    left: 0;
    position: absolute;
}
.generated-html > ul > ul > ul > li{
    margin-left: 46px;
    padding-left: 16px;
    position: relative;
}
.generated-html > ul > ul > ul > li::before {
    content: '';
    height: 5px;
    width: 5px;
    background-color: black;
    top: 16px;
    left: 0;
    position: absolute;
}
.generated-html > ul > ul > ul > ul > li{
    margin-left: 62px;
    padding-left: 16px;
    position: relative;
}
.generated-html > ul > ul > ul > ul > li::before {
    content: '';
    height: 5px;
    width: 5px;
    background-color: black;
    top: 16px;
    left: 0;
    position: absolute;
}

.generated-html ol  {
    counter-reset: li;
}
.generated-html > ol li {
    padding-left: 30px;
    position: relative;
}
.generated-html > ol > ol li {
    margin-left: 16px;
    padding-left: 30px;
    position: relative;
}
.generated-html > ol > ol > ol li {
    margin-left: 32px;
    padding-left: 30px;
    position: relative;
}
.generated-html > ol > ol > ol > ol li {
    margin-left: 48px;
    padding-left: 30px;
    position: relative;
}
.generated-html ol li::before {
    content: counter(li)'.';
    counter-increment: li;
    left: 0;
    width: 20px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    text-align: right;
    align-self: flex-end;
    justify-self: flex-end;
    position: absolute;
}

/* encounter tab */
.read-more-clipped-corner {
    clip-path: polygon(0 0, 0 100%, calc(100% - 80px) 100%, calc(100% - 80px) calc(100% - 15px), 100% calc(100% - 15px), 100% 0)
}

/* font size in fields on mobile  */
@media (max-width: 768px) {
    input:not([type]),
    input[type="date"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="email"],
    input[type="number"],
    input[type="password"],
    input[type="search"],
    input[type="tel"],
    input[type="text"],
    input[type="time"],
    input[type="url"],
    select, select:focus,
    textarea, textarea:focus {
        font-size: 16px !important;
    }

    [contenteditable='true'] {
        font-size: 16px !important;
    }
}